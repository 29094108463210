import React, { Component } from 'react';
import './App.css';
import './ScrollDown.css';
import Home from './Home.jsx'


class App extends Component {
  render() {
    return (
      <div>
        <Home/>

      </div>
    );
  }
}

export default App;
